document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    const items = document.querySelectorAll(".experience--item");

    if (items.length > 0) {
      items.forEach((item, index) => {
        setTimeout(() => {
          item.classList.add("active");
        }, index * 150);
      });
    }
  }, 500);
});
